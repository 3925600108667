/*------------------------------------
    Sidenav
------------------------------------*/

.app-sidenav {
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  color: $base-1;
  opacity: 0;
  z-index: 10000;
  position: fixed;
  visibility: hidden;
  transition: all 0.6s;
  p {
    color: $base-1;
  }
  .sidenav-menu {
    left: -100%;
    width: 300px; //sidebar width
    height: 100%;
    min-height: 100%;
    z-index: 10001;
    max-width: 75%;
    position: fixed;
    overflow: hidden;
    background-color: $primary;
    transition: all 0.6s;
    .logo {
      top: 30px;
      left: 30px;
      color: $base-1;
      font-size: 12px;
      font-weight: 700;
      line-height: 1;
      position: absolute;
    }
    .mega-menu {
      display: none;
    }
    .button {
      top: 21px;
      right: 25px;
      width: 30px;
      height: 30px;
      z-index: 999;
      display: block;
      cursor: pointer;
      position: absolute;
      border-radius: 50%;
      border: 0 !important;
      &:before {
        top: 50%;
        left: 50%;
        width: 100%;
        height: 2px;
        content: "";
        position: absolute;
        background-color: $base-1;
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:after {
        top: 50%;
        left: 50%;
        width: 100%;
        height: 2px;
        content: "";
        position: absolute;
        background-color: $base-1;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
  .sidenav-nav {
    top: 50%;
    left: 3px;
    padding: 0;
    z-index: 99;
    position: absolute;
    list-style: none;
    transform: translate(-50%, -50%);
    li {
      height: 40px;
      color: $base-1;
      font-weight: 300;
      text-transform: uppercase;
      position: relative;
      margin-bottom: 15px;
      a {
        color: #fff;
        display: block;
      }
      span {
        top: 10px;
        left: 18px;
        opacity: 0.9;
        font-size: 24px; //side bar font size
        position: absolute;
        white-space: nowrap;
      } //side bar bullet
      //   &:before {
      //     top: 32px;
      //     left: -4px;
      //     width: 10px;
      //     height: 10px;
      //     content: "";
      //     position: absolute;
      //     border-radius: 50%;
      //     border: 7px solid $base-1;
      //   }
      &:hover {
        a:not(.active) {
          span {
            opacity: 1;
          }
        }
        .count {
          display: none;
        }
        .anchor {
          display: block;
        }
      }
      .active {
        color: $base-1;
        opacity: 1;

        span {
          text-decoration: none;
        }
      }
    }
  }
  .sidenav-links {
    right: 0;
    bottom: 0;
    width: 100%;
    height: 39px;
    padding-left: 30px;
    position: absolute;
    p {
      font-size: 14px;
      white-space: nowrap;
    }
    ul {
      float: right;
      padding-right: 30px;
      list-style: none;
      li {
        font-size: 14px;
        margin-left: 15px;
        display: inline-block;
      }
    }
  }
  .sidenav-close {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.75;
    position: fixed;
    background: $base-0;
  }
}
.app-sidenav.active {
  opacity: 1;
  left: 0;
  visibility: visible;
  .sidenav-menu {
    left: 0;
  }
}
@media (max-width: 768px) {
  .app-sidenav {
    .sidenav-nav {
      li {
        margin-bottom: 5px;
        span {
          font-size: 24px;
        }
        &:before {
          top: 23px;
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .app-sidenav {
    .sidenav-menu {
      .mega-menu {
        a {
          font-size: 14px !important;
          padding-left: 15px !important;
          padding-right: 15px !important;
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .app-sidenav {
    opacity: 0;
    visibility: hidden;
  }
}
